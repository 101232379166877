import {memo, useCallback, useEffect, useRef, useState} from 'react';

import {ReactGrid} from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.scss';
import '../../styles/spreadsheet/index.scss';
import {bool} from 'prop-types';

import useProjects from '../../hooks/providers/useProjects';
import useProjectsCustomMappings from '../../hooks/providers/useProjectsCustomMappings';
import {addContextMenuOption, CUSTOM_MAPPINGS_COLUMNS_NAMES as COLUMNS, CUSTOM_MAPPINGS_HEADER_ROW as HEADER_ROW, getCustomMappingsColumns} from '../../utils';
import TextCellWithDragIndicator from '../embed/spreadsheet/cell-templates/TextCellWithDragIndicator';
import ToggleRowVisibilityCellTemplate from '../embed/spreadsheet/cell-templates/ToggleVisibilityCellTemplate';

const CustomMappingsSpreadsheet = ({hiddenRowsVisible}) => {
  const {selectedProject} = useProjects();
  const {projectCustomMappings, setProjectCustomMappings: setData} = useProjectsCustomMappings();

  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedColumnId, setSelectedColumnId] = useState(null);
  const [customColumnsWidth, setCustomColumnsWidth] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const gridRef = useRef();

  const {siren} = selectedProject;
  const data = projectCustomMappings.get(siren);

  const handleCellFocusChange = cellLocation => {
    setSelectedRowId(cellLocation.rowId);
    setSelectedColumnId(cellLocation.columnId);
  };

  const toggleRowVisibility = rowId => {
    setData(previousMap => {
      const previousData = previousMap.get(siren);
      const updatedData = [...previousData.map(e => ({...e}))];

      const entryBeforeUpdateIndex = previousData.findIndex(entry => entry.idx === rowId);
      const entryBeforeUpdate = previousData[entryBeforeUpdateIndex];
      const updatedEntry = {
        ...entryBeforeUpdate,
        is_visible: !entryBeforeUpdate.is_visible
      };

      updatedData[entryBeforeUpdateIndex] = updatedEntry;
      previousMap.set(siren, updatedData);
      const updatedMap = new Map(previousMap);
      return updatedMap;
    });
  };

  const getRows = accountingData => {
    const r = [];
    accountingData.forEach(entry => {
      const rowId = entry.idx;

      const entryLabel = entry.comptenum && entry.comptelib ? `${entry.comptenum} - ${entry.comptelib}` : '';
      const hiddenClassname = !entry.is_visible ? 'hidden-row-cell ' : '';

      if (hiddenRowsVisible || (!hiddenRowsVisible && entry.is_visible)) {
        r.push({
          rowId,
          height: 40,
          reorderable: true,
          cells: [
            {
              nonEditable: true,
              shouldDisplayDragIndicator: selectedRows.find(rId => rId === rowId),
              type: 'dragCell',
              text: entryLabel,
              className: `drag-cell ${hiddenClassname}`
            },
            {
              type: 'text',
              text: entry.mapping_sig || '',
              className: hiddenClassname
            },
            {
              type: 'text',
              text: entry.mapping_name || '',
              className: hiddenClassname
            },
            {
              type: 'text',
              text: entry.mapping_subname || '',
              className: hiddenClassname
            },
            {
              type: 'toggleRowVisibility',
              isVisible: entry.is_visible,
              onToggle: () => toggleRowVisibility(rowId)
            }
          ]
        });
      }
    });
    return [HEADER_ROW, ...r];
  };

  const columns = getCustomMappingsColumns(customColumnsWidth);
  const rows = getRows(data);

  const reorderArray = (arr, idxs, to) => {
    const movedElements = arr.filter((_, idx) => idxs.includes(idx));
    // eslint-disable-next-line no-param-reassign
    const targetIdx = Math.min(...idxs) < to ? (to += 1) : (to -= idxs.filter(idx => idx < to).length);
    const leftSide = arr.filter((_, idx) => idx < targetIdx && !idxs.includes(idx));
    const rightSide = arr.filter((_, idx) => idx >= targetIdx && !idxs.includes(idx));
    return [...leftSide, ...movedElements, ...rightSide];
  };

  const handleRowsReorder = (targetRowId, rowIds) => {
    setData(previousMap => {
      const previousData = previousMap.get(siren);

      const toIndex = data.findIndex(entry => entry.idx === targetRowId);
      const rowsIds = rowIds.map(id => data.findIndex(entry => entry.idx === id));
      const to = data[toIndex];

      const updatedDataWithNewCategories = previousData.map((entry, idx) => {
        if (rowsIds.includes(idx)) {
          return {
            ...entry,
            mapping_name: to.mapping_name,
            mapping_subname: to.mapping_subname,
            mapping_sig: to.mapping_sig,
            mapping_order: to.mapping_order
          };
        }
        return {...entry};
      });

      const updatedData = reorderArray(updatedDataWithNewCategories, rowsIds, toIndex);
      previousMap.set(siren, updatedData);
      const updatedMap = new Map(previousMap);
      return updatedMap;
    });
  };

  const handleCanReorderRows = targetRowId => {
    return targetRowId !== 'header';
  };

  function doubleSortByOrderAndNumber(unsortedData) {
    // 1. Group the data by the "mapping_order" property
    const groupedByOrder = unsortedData.reduce((acc, item) => {
      const {mapping_order: mappingOrder, comptenum} = item;
      if (!acc[mappingOrder]) {
        acc[mappingOrder] = [];
      }
      acc[mappingOrder].push({...item});
      return acc;
    }, {});

    // 2. Sort each group by the "comptenum" property
    const sortedData = Object.values(groupedByOrder).flatMap(group => group.sort((a, b) => parseInt(a.comptenum, 10) - parseInt(b.comptenum, 10)));

    return sortedData;
  }

  const applyChangesToEntries = (changes, previousMap) => {
    const previousData = previousMap.get(siren);
    const updatedData = [...previousData.map(e => ({...e}))];

    // eslint-disable-next-line no-restricted-syntax
    for (const change of changes) {
      const {rowId, columnId} = change;

      const oldValue = change.previousCell.text;
      const newValue = change.newCell.text;

      if (newValue !== oldValue) {
        const itemToUpdateIndex = updatedData.findIndex(item => item.idx === rowId);
        const updatedItem = updatedData[itemToUpdateIndex];
        updatedItem[columnId] = newValue;
        updatedData[itemToUpdateIndex] = updatedItem;
      }
    }
    const orderedData = doubleSortByOrderAndNumber(updatedData);
    previousMap.set(siren, orderedData);
    const updatedMap = new Map(previousMap);

    return updatedMap;
  };

  const handleChanges = changes => {
    setData(previousDataMap => applyChangesToEntries(changes, previousDataMap));
  };

  const handleDeleteMapping = (mappingKey, deletedMapping) => {
    setData(previousMap => {
      const previousData = previousMap.get(siren);
      const updatedData = [...previousData];

      const dataWithoutDeletedCategory = updatedData.map(entry => ({
        ...entry,
        [mappingKey]: entry[mappingKey] === deletedMapping ? '' : entry[mappingKey]
      }));

      previousMap.set(siren, dataWithoutDeletedCategory);
      const updatedMap = new Map(previousMap);

      return updatedMap;
    });
  };

  const addEmptyNewLine = () => {
    const allIds = data.map(entry => entry.idx);
    // .filter(val => val !== null && val !== undefined);
    const maxRowId = Math.max(...allIds);
    const newRowId = (maxRowId || 0) + 1;

    setData(previousMap => {
      const previousData = previousMap.get(siren);
      const updatedData = [...previousData];

      const newEntry = {
        idx: newRowId,
        comptenum: '',
        comptelib: '',
        mapping_sig: '',
        mapping_name: '',
        mapping_subname: ''
      };
      updatedData.push(newEntry);

      previousMap.set(siren, updatedData);
      const updatedMap = new Map(previousMap);
      return updatedMap;
    });
  };

  const handleContextMenu = (selectedRowIds, selectedColIds, selectionMode, menuOptions) => {
    if (selectedRowId === null) {
      return [];
    }

    let options = [...menuOptions];
    const currentRow = rows.find(r => r.rowId === selectedRowId);

    if (selectedRowIds.length <= 1) {
      options = addContextMenuOption(options, 'addChildRow', 'Ajouter une ligne', addEmptyNewLine);
    }

    if (selectedColumnId === COLUMNS.mappingSig) {
      const selectedSig = currentRow?.cells[1]?.text;
      options = addContextMenuOption(options, 'deleteSigOption', 'Supprimer le SIG', () => handleDeleteMapping('mapping_sig', selectedSig));
    }

    if (selectedColumnId === COLUMNS.mappingName) {
      const selectedCategory = currentRow?.cells[2]?.text;
      options = addContextMenuOption(options, 'deleteCategoryOption', 'Supprimer la catégorie', () => handleDeleteMapping('mapping_name', selectedCategory));
    }

    if (selectedColumnId === COLUMNS.mappingSubname) {
      const selectedSubCategory = currentRow?.cells[3]?.text;
      options = addContextMenuOption(options, 'deleteSubcategoryOption', 'Supprimer la sous-catégorie', () => handleDeleteMapping('mapping_subname', selectedSubCategory));
    }

    return options;
  };

  const handleColumnResize = useCallback((columnId, widthAfterResize) => {
    setCustomColumnsWidth(prevColumnsWidth => {
      const columnIndex = prevColumnsWidth.findIndex(el => el.id === columnId);
      if (columnIndex === -1) {
        prevColumnsWidth.push({
          id: columnId,
          width: widthAfterResize
        });
      } else {
        const resizedColumn = prevColumnsWidth[columnIndex];
        const updatedColumn = {...resizedColumn, width: widthAfterResize};
        // eslint-disable-next-line no-param-reassign
        prevColumnsWidth[columnIndex] = updatedColumn;
      }
      return [...prevColumnsWidth];
    });
  }, []);

  // This hooks keeps track of which rows are currently selected
  useEffect(() => {
    const interval = setInterval(() => {
      if (gridRef.current) {
        const currentSelection = gridRef.current.state.selectedIds;
        setSelectedRows(currentSelection);
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="centered-spreadsheet" id={`reactgrid-${process.env.REACT_APP_COMPANY_NAME}`}>
      <ReactGrid
        ref={gridRef}
        onFocusLocationChanged={handleCellFocusChange}
        onContextMenu={handleContextMenu}
        canReorderRows={handleCanReorderRows}
        onRowsReordered={handleRowsReorder}
        onCellsChanged={handleChanges}
        rows={rows}
        columns={columns}
        enableFillHandle
        enableRowSelection
        onColumnResized={handleColumnResize}
        stickyTopRows={1}
        customCellTemplates={{
          toggleRowVisibility: new ToggleRowVisibilityCellTemplate(),
          dragCell: new TextCellWithDragIndicator()
        }}
      />
    </div>
  );
};

CustomMappingsSpreadsheet.propTypes = {
  hiddenRowsVisible: bool.isRequired
};

export default memo(CustomMappingsSpreadsheet);
