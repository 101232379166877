import * as Sentry from '@sentry/react';
import {createBrowserRouter} from 'react-router-dom';

import routes from './routes';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const Router = sentryCreateBrowserRouter(
  routes,
  // Preparing for react-router V7 , fixes warnings in tests
  // See : https://reactrouter.com/en/6.28.0/upgrading/future#v7_relativesplatpath
  // See : https://reactrouter.com/en/6.28.0/upgrading/future#v7_starttransition
  {
    future: {
      v7_relativeSplatPath: true,
      v7_startTransition: true
    }
  }
);

export default Router;
