import {APP_ROUTES} from './const';
import RequireAuth from './hocs/RequireAuth';
import RequireNecessaryData from './hocs/RequireNecessaryData';
import RequireProducts from './hocs/RequireProducts';
import RequireSubscription from './hocs/RequireSubscription';
import Layout from './Layout';
import Bills from './pages/Bills';
import ErrorComponent from './pages/ErrorComponent';
import Folders from './pages/Folders';
import Home from './pages/Home';
import IframeDisplay from './pages/IframeDisplay';
import Login from './pages/Login';
import PaymentSuccess from './pages/PaymentSuccess';
import Products from './pages/Products';
import Profile from './pages/Profile';
import RestrictedAccess from './pages/RestrictedAccess';
import Subscribe from './pages/Subscribe';
import TestTranscript from './pages/TestTranscript';
import TiimeAuthenticationCallbackPage from './pages/TiimeAuthenticationCallbackPage';

const routes = [
  {
    path: APP_ROUTES.login,
    errorElement: <ErrorComponent />,
    element: (
      <RequireNecessaryData numberOfApiCallsToMake={1}>
        <Login />
      </RequireNecessaryData>
    )
  },
  {
    path: '/tiime-oauth-callback',
    errorElement: <ErrorComponent />,
    element: (
      <RequireNecessaryData numberOfApiCallsToMake={1}>
        <TiimeAuthenticationCallbackPage />
      </RequireNecessaryData>
    )
  },
  {
    path: APP_ROUTES.subscribe,
    errorElement: <ErrorComponent />,
    element: (
      <RequireAuth numberOfApiCallsToMake={4}>
        <RequireNecessaryData>
          <Subscribe />
        </RequireNecessaryData>
      </RequireAuth>
    )
  },
  {
    path: APP_ROUTES.home,
    errorElement: <ErrorComponent />,
    children: [
      {
        path: APP_ROUTES.home,
        element: (
          <RequireAuth numberOfApiCallsToMake={4}>
            <RequireSubscription>
              <RequireNecessaryData>
                <Layout>
                  <Home />
                </Layout>
              </RequireNecessaryData>
            </RequireSubscription>
          </RequireAuth>
        )
      },
      {
        path: 'test-saisie-vocale',
        element: (
          <RequireAuth numberOfApiCallsToMake={0}>
            <RequireNecessaryData>
              <Layout>
                <TestTranscript />
              </Layout>
            </RequireNecessaryData>
          </RequireAuth>
        )
      },

      // The following route may seem a bit hacky : in fact, the <Report> page is included INSIDE the Layout component.
      // That's why the Layout component here does not have any page child here.
      // This modification has been done to implement Power BI reports bootstrapping (preloading).
      // Whatever the page we land on, the <Report> is present inside the layout. It is just hidden to the user and pre-loaded to improve perf and loading times.
      // Once we land on the <Report> page (url /reports/:reportName), the PowerBIClientReact component is fed with correct reportId, embedUrl, accessToken and report is rendered way faster.
      // For more information, see :
      // PowerBI docs : https://learn.microsoft.com/en-us/javascript/api/overview/powerbi/bootstrap-better-performance
      // PowerBI-client-react implementation: https://github.com/microsoft/powerbi-client-react?tab=readme-ov-file#how-to-bootstrap-a-powerbi-report
      {
        path: 'reports/:reportName',
        element: (
          <RequireAuth numberOfApiCallsToMake={4}>
            <RequireSubscription requiresSubscriptionOrTrial>
              <RequireNecessaryData>
                <Layout />
              </RequireNecessaryData>
            </RequireSubscription>
          </RequireAuth>
        )
      },
      {
        path: APP_ROUTES.projects,
        element: (
          <RequireAuth numberOfApiCallsToMake={4}>
            <RequireSubscription>
              <RequireNecessaryData>
                {/*
                  // We want to display content and make API calls ASAP on projects page. In order to do that and because reports are not really needed on this page
                  // We do not wait for reports to be loaded to display page content.
                */}
                <Layout shouldWaitForReportsLoading={false}>
                  <Folders />
                </Layout>
              </RequireNecessaryData>
            </RequireSubscription>
          </RequireAuth>
        )
      },
      {
        path: APP_ROUTES.profile,
        element: (
          <RequireAuth numberOfApiCallsToMake={5}>
            <RequireSubscription>
              <RequireProducts>
                <RequireNecessaryData>
                  <Layout>
                    <Profile />
                  </Layout>
                </RequireNecessaryData>
              </RequireProducts>
            </RequireSubscription>
          </RequireAuth>
        )
      },
      {
        path: APP_ROUTES.subscription,
        element: (
          <RequireAuth numberOfApiCallsToMake={5}>
            <RequireSubscription>
              <RequireProducts>
                <RequireNecessaryData>
                  <Layout>
                    <Products />
                  </Layout>
                </RequireNecessaryData>
              </RequireProducts>
            </RequireSubscription>
          </RequireAuth>
        )
      },
      {
        path: APP_ROUTES.thanksForPurchase,
        element: (
          <RequireAuth numberOfApiCallsToMake={3}>
            <RequireNecessaryData>
              <Layout>
                <PaymentSuccess />
              </Layout>
            </RequireNecessaryData>
          </RequireAuth>
        )
      },
      {
        path: APP_ROUTES.restrictedAccess,
        element: (
          <RequireAuth numberOfApiCallsToMake={3}>
            <RequireNecessaryData>
              <Layout>
                <RestrictedAccess />
              </Layout>
            </RequireNecessaryData>
          </RequireAuth>
        )
      },
      {
        path: APP_ROUTES.bills,
        element: (
          <RequireAuth numberOfApiCallsToMake={4}>
            <RequireSubscription>
              <RequireNecessaryData>
                <Layout>
                  <Bills />
                </Layout>
              </RequireNecessaryData>
            </RequireSubscription>
          </RequireAuth>
        )
      },
      {
        path: '/:idDocumentGroup/:idDocument',
        element: (
          <RequireAuth numberOfApiCallsToMake={3}>
            <RequireNecessaryData>
              {/*
                // We want to display content and make API calls ASAP on onboarding tutorial page. In order to do that and because reports are not really needed on this page
                // We do not wait for reports to be loaded to display page content.
              */}
              <Layout shouldWaitForReportsLoading={false}>
                <IframeDisplay />
              </Layout>
            </RequireNecessaryData>
          </RequireAuth>
        )
      }
    ]
  }
];

export default routes;
