import {memo, useCallback, useEffect, useState} from 'react';

import {ReactGrid} from '@silevis/reactgrid';
import {bool} from 'prop-types';

import '@silevis/reactgrid/styles.scss';
import '../../../styles/spreadsheet/index.scss';

import {SUMMARY_THEMES} from '../../../const';
import useDimensions from '../../../hooks/dom/useDimensions';
import useReportSummary from '../../../hooks/providers/useReportSummary';
import {
  getSummariesShareDataColumns,
  getSummariesShareAnonymizedDataColumns,
  SUMMARIES_SHARE_ANONYMIZED_DATA_SPREADSHEET_COLUMNS as ANONYMIZED_HEADER_ROW,
  getHeaderRow,
  groupEntriesAmountsByDate
} from '../../../utils';
import DeleteCellTemplate from '../spreadsheet/cell-templates/DeleteCellTemplate';
import TooltipTextCellTemplate from '../spreadsheet/cell-templates/TooltipTextCellTemplate';

const formatDataToAccountingCompliantFormat = spreadsheetData => {
  const accountingCompliantFormattedData = spreadsheetData.map(entry => ({
    Mois: entry.date,
    Compte: entry.label,
    Montant: entry.amount,
    Catégorie: entry.category,
    Theme: entry.theme
  }));
  return accountingCompliantFormattedData;
};

const SummaryDataSpreadsheet = ({isAnonymizedData}) => {
  const [formattedData, setFormattedData] = useState([]);
  const [rows, setRows] = useState([]);
  const [ref, {width}] = useDimensions();
  const [customColumnsWidth, setCustomColumnsWidth] = useState([]);

  const {summaryTheme, dataToShare: data, setDataToShare: updateData} = useReportSummary();

  const deleteRow = rowId => {
    setFormattedData(currentData => {
      const updatedData = [...currentData.filter(entry => entry.id !== rowId)];
      const accountingCompliantFormattedData = formatDataToAccountingCompliantFormat(updatedData);
      updateData(accountingCompliantFormattedData);
      return updatedData;
    });
  };

  const getRows = accoutingData => {
    const r = [];
    const isCaTheme = summaryTheme === SUMMARY_THEMES.ca;

    accoutingData.forEach(entry => {
      const categoryCell = {nonEditable: true, type: 'text', text: entry.category};
      const labelCell = {
        type: 'tooltipTextCell',
        tooltipContent: 'Double-cliquez pour modifier le libellé',
        text: entry.label
      };

      r.push({
        rowId: entry.id,
        height: 40,
        cells: [
          {
            nonEditable: true,
            type: 'text',
            text: entry.date
          },
          ...(!isAnonymizedData && !isCaTheme ? [categoryCell] : []),
          ...(!isAnonymizedData ? [labelCell] : []),
          {
            nonEditable: true,
            type: 'number',
            value: entry.amount,
            format: new Intl.NumberFormat('fr-FR')
          },
          {type: 'deleteRow', onDelete: () => deleteRow(entry.id)}
        ]
      });
    });

    if (isAnonymizedData) {
      return [ANONYMIZED_HEADER_ROW, ...r];
    }

    const headerRow = getHeaderRow(summaryTheme);
    return [headerRow, ...r];
  };

  const applyChangesToEntries = (changes, previousData) => {
    const updatedData = [...previousData];

    changes.forEach(change => {
      const {rowId, columnId} = change;

      const oldValue = change.previousCell.text;
      const newValue = change.newCell.text;

      if (newValue !== oldValue) {
        const itemToUpdateIndex = updatedData.findIndex(item => item.id === rowId);
        const updatedItem = updatedData[itemToUpdateIndex];
        updatedItem[columnId] = newValue;
        updatedData[itemToUpdateIndex] = updatedItem;
      }
    });

    return [...updatedData];
  };

  const handleChanges = changes => {
    setFormattedData(previousData => {
      const updatedData = applyChangesToEntries(changes, previousData);
      // reformat data to an accounting-compliant format before sending to API
      const accountingCompliantFormattedData = formatDataToAccountingCompliantFormat(updatedData);
      updateData(accountingCompliantFormattedData);
      return updatedData;
    });
  };

  const handleColumnResize = useCallback((columnId, widthAfterResize) => {
    setCustomColumnsWidth(prevColumnsWidth => {
      const columnIndex = prevColumnsWidth.findIndex(el => el.id === columnId);
      if (columnIndex === -1) {
        prevColumnsWidth.push({
          id: columnId,
          width: widthAfterResize
        });
      } else {
        const resizedColumn = prevColumnsWidth[columnIndex];
        const updatedColumn = {...resizedColumn, width: widthAfterResize};
        // eslint-disable-next-line no-param-reassign
        prevColumnsWidth[columnIndex] = updatedColumn;
      }
      return [...prevColumnsWidth];
    });
  }, []);

  // Initial formatting / setting spreadsheet data from props raw data to a cleaner / more usable format
  useEffect(() => {
    const themeData = data.filter(entry => entry.Theme === summaryTheme);
    const dataToFormat = isAnonymizedData ? groupEntriesAmountsByDate(themeData) : themeData;
    const dataAfterFormatting = dataToFormat.map((entry, index) => ({
      date: entry.Mois,
      category: entry.Catégorie || '',
      amount: parseFloat(entry.Montant),
      label: entry.Compte,
      theme: entry.Theme,
      id: index
    }));
    setFormattedData(dataAfterFormatting);
  }, [data, isAnonymizedData]);

  // Re-painting rows on every formattedData change
  useEffect(() => {
    const newRows = getRows(formattedData);
    setRows(newRows);
  }, [formattedData]);

  if (formattedData.length === 0) return null;

  const columns = isAnonymizedData ? getSummariesShareAnonymizedDataColumns() : getSummariesShareDataColumns(customColumnsWidth, width, summaryTheme);

  return (
    <div ref={ref} className="centered-spreadsheet" id={`reactgrid-${process.env.REACT_APP_COMPANY_NAME}`}>
      <ReactGrid
        onCellsChanged={handleChanges}
        rows={rows}
        columns={columns}
        onColumnResized={handleColumnResize}
        customCellTemplates={{
          deleteRow: new DeleteCellTemplate(),
          tooltipTextCell: new TooltipTextCellTemplate()
        }}
      />
    </div>
  );
};

SummaryDataSpreadsheet.propTypes = {
  isAnonymizedData: bool.isRequired
};

export default memo(SummaryDataSpreadsheet);
