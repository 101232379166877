// [WARN] Do not shorten this import . It will create a circular dependency issue
import {models} from 'powerbi-client';

import {makeHumanFriendlyUrl} from './utils/data-formatting';

const BASE_URL = process.env.REACT_APP_PBIE_API_BASE_URL;

const API_ENDPOINTS = {
  payment: {
    get: `${BASE_URL}/get-customer-subscriptions`,
    create: `${BASE_URL}/create-checkout-session`,
    update: `${BASE_URL}/update-subscription`,
    bills: `${BASE_URL}/get-customer-invoices`,
    products: `${BASE_URL}/get-product-list`,
    authorizations: `${BASE_URL}/get-projects-authorization`
  },
  projects: {
    create: {
      manual: `${BASE_URL}/create-project`,
      myUnisoft: `${BASE_URL}/create-myunisoft-folder`,
      pennylane: `${BASE_URL}/create-penny-folder`,
      cegidQuadra: `${BASE_URL}/create-cegid-quadra-folder`,
      vectron: `${BASE_URL}/create-vectron-folder`,
      acd: `${BASE_URL}/create-acd-folder`,
      tiime: `${BASE_URL}/create-tiime-folder`
    },
    tiime: {
      checkToken: `${BASE_URL}/verify-tiime-refresh-token`,
      createToken: `${BASE_URL}/create-tiime-connection`
    },
    pennylaneDataIntegrationStatus: `${BASE_URL}/check-update-folder`,
    import: `${BASE_URL}/push-fec`,
    findAll: `${BASE_URL}/get-my-projects`,
    update: `${BASE_URL}/update-project-parameters`,
    delete: `${BASE_URL}/delete-project`,
    addUser: `${BASE_URL}/invite-user`,
    deleteUser: `${BASE_URL}/delete-user-from-project`,
    contact: `${BASE_URL}/push-contact`,
    demo: `${BASE_URL}/set-demo`,
    refresh: {
      manual: `${BASE_URL}/run-manual-update`,
      enableDisableAuto: `${BASE_URL}/set-auto-update`
    }
  },
  workspaces: {
    findAll: `${BASE_URL}/get-workspaces`,
    create: `${BASE_URL}/create-workspace`,
    delete: `${BASE_URL}/delete-workspace`,
    addUser: `${BASE_URL}/add-workspace-user`,
    deleteUser: `${BASE_URL}/delete-workspace-user`
  },
  users: {
    promoteOrDemote: `${BASE_URL}/upgrade-downgrade-owner`
  },
  profile: {
    me: `${BASE_URL}/me`,
    update: `${BASE_URL}/update-profile`,
    delete: `${BASE_URL}/delete-me`,
    getHelp: `${BASE_URL}/get-help`
  },
  consents: {
    get: `${BASE_URL}/get-consentments`,
    save: `${BASE_URL}/push-consentments`
  },
  miscellaneous: {
    maintenance: `${BASE_URL}/maintenance/get-maintenance-status`
  },
  reports: {
    saveAs: `${BASE_URL}/push-report-save-as`,
    create: `${BASE_URL}/create-report`,
    import: `${BASE_URL}/import-report`,
    export: `${BASE_URL}/export-report`,
    delete: `${BASE_URL}/delete-report`,
    getToken: `${BASE_URL}/get-embed-token`,
    deleteDefiPreviReportBudgetData: `${BASE_URL}/delete-budget`,
    createDefiPreviReportBudgetData: `${BASE_URL}/create-budget`,
    getSpreadsheetData: `${BASE_URL}/get-data-for-spreadsheet`,
    updateSpreadsheetData: `${BASE_URL}/push-data-from-spreadsheet`,
    getReportThemeAudio: `${BASE_URL}/read-audio`,
    sendDocumentRequest: `${BASE_URL}/send-documents-request`,
    transcriptWorkingDay: `${BASE_URL}/transcript-working-day`,
    sendTranscription: `${BASE_URL}/send-transcription`,
    summary: {
      getData: `${BASE_URL}/get-summaries-data`,
      getModels: `${BASE_URL}/get-prompt-models`,
      saveModel: `${BASE_URL}/save-prompt`,
      deleteModel: `${BASE_URL}/delete-prompt-model`,
      update: `${BASE_URL}/update-summary`,
      generateSummary: 'https://drivn-functions-copilot.azurewebsites.net/generate-summary'
    }
  },
  chatbot: {
    getAssistantsAndThreads: `${BASE_URL}/get-copilot`,
    create: `${BASE_URL}/create-empty-thread`,
    delete: `${BASE_URL}/delete-thread`,
    // addMessage: `${BASE_URL}/copilot/add-message-to-thread`,
    addMessage: 'https://drivn-functions-copilot.azurewebsites.net/add-message-to-thread',
    getThreadMessages: `${BASE_URL}/get-thread-content`,
    sendFile: `${BASE_URL}/upload-file-for-copilot`
  },
  mappings: {
    get: `${BASE_URL}/get-custom-mapping`,
    reset: `${BASE_URL}/initialize-custom-mapping`,
    update: `${BASE_URL}/update-custom-mapping`
  }
};

const CONNECTORS = {
  unisoft: 'MYUNISOFT',
  pennylane: 'PENNYLANE',
  manual: 'MANUAL',
  vectron: 'VECTRON',
  cegidQuadra: 'CEGID_QUADRA',
  acd: 'ACD',
  tiime: 'TIIME'
};

const MONTHS = [
  {
    name: 'Janvier',
    id: 1
  },
  {
    name: 'Février',
    id: 2
  },
  {
    name: 'Mars',
    id: 3
  },
  {
    name: 'Avril',
    id: 4
  },
  {
    name: 'Mai',
    id: 5
  },
  {
    name: 'Juin',
    id: 6
  },
  {
    name: 'Juillet',
    id: 7
  },
  {
    name: 'Août',
    id: 8
  },
  {
    name: 'Septembre',
    id: 9
  },
  {
    name: 'Octobre',
    id: 10
  },
  {
    name: 'Novembre',
    id: 11
  },
  {
    name: 'Décembre',
    id: 12
  }
];

const DEFAULT_PROJECTS_SCHEMAS = {
  gestion: 'fec',
  staff: 'dsn',
  previ: 'previ',
  z: 'z'
};

const POWER_BI_REPORTS = [
  {
    name: 'DeFi Gestion',
    module: DEFAULT_PROJECTS_SCHEMAS.gestion
  },
  {
    name: 'DeFi Gestion [Dev]',
    module: DEFAULT_PROJECTS_SCHEMAS.gestion
  },
  {
    name: 'DeFi Prévi',
    module: DEFAULT_PROJECTS_SCHEMAS.previ
  },
  {
    name: 'DeFi Prévi [Dev]',
    module: DEFAULT_PROJECTS_SCHEMAS.previ
  },
  {
    name: 'DeFi Gestion [Test]',
    module: DEFAULT_PROJECTS_SCHEMAS.gestion
  },
  {
    name: 'DeFi Z [Dev]',
    module: DEFAULT_PROJECTS_SCHEMAS.z
  },
  {
    name: 'DeFi Z',
    module: DEFAULT_PROJECTS_SCHEMAS.z
  }
];

const POWER_BI_PAGES = {
  filters: {
    title: 'Filters',
    id: 'ReportSection4dceaab95e7ea0702b93'
  },
  reportHome: {
    title: "Mon chiffre d'affaires",
    id: 'ReportSection'
  },
  reportCharges: {
    title: 'Mes charges',
    id: 'ReportSection10cab94bd5d4594e4bd4'
  },
  reportSynthesis: {
    title: 'Synthèse',
    id: 'ReportSection6bc42c10a4c88850de74'
  },
  clients: {
    title: 'Mes clients',
    id: 'ReportSectionbe780789d5b4e851ab0c'
  },
  defiPreviReportTreasury: {
    title: 'Home',
    id: 'ReportSection6d55f3357cabde321e79'
  },
  defiPreviReportHome: {
    title: "Prévisionnel à partir de l'historique",
    id: 'ReportSection5fa3ecdf8aa2e7835c76'
  },
  defiPreviReportBudget: {
    title: 'Prévisionnel à partir du budget',
    id: 'ReportSection973656b77606027b2cc3'
  },
  projectChoiceZ: {
    title: 'Project choice',
    id: 'ReportSectioncf4bd7dd0708a2b38363'
  }
};

const POWER_BI_REPORT_FILTERS = [
  {
    title: 'Projet',
    name: 'f8c4f35048580c801620',
    table: 'siren_parameters',
    column: 'project_name'
  },
  {
    title: 'Exercice Fiscal',
    name: '3ad855ef8b719c7ac0d0',
    table: 'fec',
    column: 'Exercice fiscal'
  },
  {
    title: 'Mois',
    name: '9537e0758d16b0e3c606',
    table: 'fec',
    column: 'Mois'
  },
  {
    title: 'Journal',
    name: 'fcdabfabe4c32603e165',
    table: 'fec',
    column: 'journallib'
  },
  {
    title: 'Compte',
    name: 'd5e9ea40d5a094a4510e',
    table: 'fec',
    column: 'comptelib'
  },
  {
    title: 'ChicletSlicer 1.6.3', // Page CA, slicer mensuel/cumul
    name: '0e64f0ded2f1ee9474ce',
    table: 'Paramètre M/YTD',
    column: 'Paramètre M/YTD Champs'
  },
  {
    title: 'ChicletSlicer 1.6.3', // Page charges, slicer mensuel/cumul
    name: 'a01bfbe906056a03786c',
    table: 'Paramètre M/YTD',
    column: 'Paramètre M/YTD Champs'
  }
];

const POWER_BI_SLICERS = {
  projectChoice: {
    id: 'a02fd76d1d984c13987d'
  },
  thresholdTreasury: {
    id: '021612936bddc0c01486'
  },
  thresholdImposition: {
    id: 'ba56f0665eaa4c403b9c'
  },
  chooseBudget: {
    id: 'b995e5789abc377dbb20'
  },
  chargesTabs: {
    id: 'e022bcdaf579a3acb2e1'
  }
};

const POWER_BI_VISUALS = {
  accountantExpectedElements: {
    name: 'cb0a8c72e408719262a3',
    title: 'Les éléments attendus par mon comptable (Compte 471)'
  }
};

const POWER_BI_REPORT_BUTTONS = {
  resetFilters: {
    bookmark: 'Bookmark97a1b8fe00a059b19891',
    id: 'd675a4d2a2b20acc0895'
  }
};

const POWER_BI_REPORT_BOOKMARKS = {
  ecarts: {
    title: 'Ecarts',
    name: 'Bookmarke76a16f8bd9a56667ba5'
  },
  ratio: {
    title: 'Ratio CA',
    name: 'Bookmark08ddd257ab418678bcb9'
  },
  filters: {
    title: 'Filters',
    name: 'Bookmark062b11cc5cddd8250b50'
  },
  reportHome: {
    title: 'Home',
    name: 'Bookmarkfa38f228868b013063b0'
  },
  openOrCloseFilters: {
    title: 'pop filters',
    name: 'Bookmarke6d8df775752999070b7'
  },
  treasuryTabDefiPrevi: {
    title: 'tab trésorerie report Defi Previ',
    name: 'Bookmark15ee581709d2e5e415d1'
  },
  impositionTabDefiPrevi: {
    title: 'tab imposition report Defi Previ',
    name: 'Bookmarkec2909a7cb0692c1796e'
  },
  accountantExpectedElements: {
    title: 'Les éléments attendus par mon comptable',
    name: 'Bookmark52c4635248f0ce2a1953'
  },
  treasuryDefiGestion: {
    title: 'Init phase 2',
    name: 'Bookmarkfe36f26cc67ce6caed29'
  },
  projectChoiceDefiGestion: {
    title: 'Init phase 1',
    name: 'Bookmark01b9f80e4678744e4358'
  },
  soldes: {
    title: 'Mes solde de gestion',
    name: 'Bookmarkd142593f14af4af99047'
  },
  customMapping: {
    title: 'Plan comptable personnalisé',
    name: '8bb36709c3decb00675d'
  },
  defaultMapping: {
    title: 'Plan comptable général',
    name: '34816650b9d5d7ca90b0'
  }
};

const STRIPE_PORTAL_URLS = {
  dev: 'https://billing.stripe.com/p/login/test_aEUbLdaNXejFcEMeUU',
  prod: 'https://billing.stripe.com/p/login/dR66qAeYp84Y2UU4gg'
};

const REPORT_LABELS = {
  fec: 'DeFi Gestion',
  z: 'DeFi Z',
  previ: 'DeFi Prévi',
  notice: 'Notice'
};

const SNACKBAR_ACTIONS = {
  ADD_USER_SUCCESS: 'addUserSuccess',
  DELETE_USER_SUCCESS: 'deleteUserSuccess',
  TECHNICAL_ERROR: 'technicalError',
  SEND_CONTACT_MESSAGE_SUCCESS: 'sendContactMessageSuccess',
  CREATE_DEFI_PREVI_BUDGET_SUCCESS: 'createDefiPreviBudgetSucess',
  DELETE_DEFI_PREVI_BUDGET_SUCCESS: 'deleteDefiPreviBudgetSuccess',
  IMPORT_PROJECT_FILES_SUCCESS: 'importProjectFilesSuccess',
  CREATE_PROJECT_SUCCESS: 'createProjectSuccess',
  CREATE_PROJECT_SUCCESS_WITH_NAME: 'createProjectSuccessWithName',
  UPDATE_PROJECT_SUCCESS: 'updateProjectSuccess',
  REFRESH_PROJECT_LOADING: 'refreshProjectLoading',
  REFRESH_PROJECT_SUCCESS: 'refreshProjectSuccess',
  PROMOTE_USER_SUCCESS: 'promoteUserSuccess',
  DEMOTE_USER_SUCCESS: 'demoteUserSuccess',
  UPDATE_PROFILE_SUCCESS: 'updateProfileSuccess',
  UPDATE_PROFILE_ERROR: 'updateProfileError',
  UPDATE_PROFILE_PARTIAL: 'updateProfilePartial',
  SUBSCRIBE_LOADING: 'subscribeLoading',
  UPLOAD_ERROR_DOCUMENT_TYPE: 'uploadErrorDocumentType',
  UPLOAD_ERROR_TOO_MANY_FILES: 'uploadErrorTooManyFiles',
  PENNYLANE_DATA_INTEGRATION_IN_PROGRESS: 'pennylaneDataIntegrationInProgress',
  PENNYLANE_DATA_INTEGRATION_COMPLETED: 'pennylaneDataIntegrationCompleted',
  PENNYLANE_DATA_INTEGRATION_ERROR: 'pennylaneDataIntegrationError',
  CREATE_WORKSPACE_SUCCESS: 'createWorkspaceSuccess',
  DELETE_WORKSPACE_SUCCESS: 'deleteWorkspaceSuccess',
  CREATE_REPORT_SUCCESS: 'createReportSuccess',
  REPORT_SUCCESSFULLY_SAVED: 'reportSuccessfullySaved',
  SEND_CHATBOT_MESSAGE_ERROR: 'sendChatbotMessageError',
  SUBSCRIBE_ERROR_EMAIL_ALREADY_TAKEN: 'subscribeErrorEmailAlreadyTaken',
  UPDATE_SPREADSHEET_DATA_IN_PROGRESS: 'updateSpreadsheetDataInProgress',
  UPDATE_SPREADSHEET_DATA_SUCCESS: 'updateSpreadsheetDataSuccess',
  UPDATE_SPREADSHEET_DATA_ERROR: 'updateSpreadsheetDataError',
  IMPORT_REPORT_SUCCESS: 'importReportSuccess',
  EXPORT_REPORT_IN_PROGRESS: 'exportReportInProgress',
  EXPORT_REPORT_SUCCESS: 'exportReportSuccess',
  UPDATE_REPORT_SUMMARY: 'updateReportSummary',
  UPDATE_REPORT_SUMMARY_WITH_AUDIO: 'updateReportSummaryWithAudio',
  SEND_DOCUMENT_REQUEST_SUCCESS: 'sendDocumentRequestSuccess',
  UPDATE_REPORT_SUMMARY_SUCCESS: 'updateReportSummarySuccess',
  USER_HAS_DECLINED_MICROPHONE_PERMISSION: 'userHasDeclinedMicrophonePermission',
  TRANSCRIPTION_AUDIO_FILE_EXCEEDS_MAXIMUM_SIZE: 'transcriptionAudioFileExceedsMaximumSize',
  TRANSCRIPTION_AUDIO_FILE_WILL_SOON_EXCEED_MAXIMUM_SIZE_WARNING: 'transcriptionAudioFileWillSoonExceedMaximumSizeWarning',
  INVALID_TRANSCRIPTION: 'invalidTranscription',
  CREATE_PROMPT_MODEL_SUCCESS: 'createPromptModelSuccess',
  DELETE_PROMPT_MODEL_SUCCESS: 'deletePromptModelSuccess',
  RESET_CUSTOM_MAPPING_IN_PROGRESS: 'resetCustomMappingInProgress',
  RESET_CUSTOM_MAPPING_SUCCESS: 'resetCustomMappingInSuccess',
  UPDATE_CUSTOM_MAPPING_IN_PROGRESS: 'updateCustomMappingInProgress',
  UPDATE_CUSTOM_MAPPING_SUCCESS: 'updateCustomMappingInSuccess'
};

const createMappingObjectBetweenReportNameAndReportUrlId = reportName => ({
  reportName,
  urlFormattedReportName: makeHumanFriendlyUrl(reportName)
});

const REPORT_URL_FORMATTED_IDS = [
  createMappingObjectBetweenReportNameAndReportUrlId(REPORT_LABELS.fec),
  createMappingObjectBetweenReportNameAndReportUrlId(REPORT_LABELS.z),
  createMappingObjectBetweenReportNameAndReportUrlId(REPORT_LABELS.previ),
  createMappingObjectBetweenReportNameAndReportUrlId(REPORT_LABELS.notice)
];

const BREAKPOINTS = {
  xs: 'extraSmall',
  sm: 'small',
  md: 'medium',
  lg: 'large',
  xl: 'extraLarge'
};

const REGULAR_EXPRESSIONS = {
  label: /^[^'";/-]*$/,
  textWithRangeChars: (minNumbersOfChars, maxNumberOfChars) => new RegExp(`^[\\s\\S]{${minNumbersOfChars},${maxNumberOfChars}}$`),
  labelWithMinimumChars: numberOfChars => new RegExp(`^[^'";/-]{${numberOfChars},}$`),
  labelWithRangeChars: (minNumbersOfChars, maxNumberOfChars) => new RegExp(`^[^'";/-]{${minNumbersOfChars},${maxNumberOfChars}}$`),
  compteNum: /^\w{6,50}$/,
  url: /^(http|https):\/\/(www\.)?[a-zA-Z0-9.-]+(:\d+)?(\/[^\s]*)?$/,
  positiveOrNegativeAmount: /^-?[0-9]*$/,
  fecFileName: /^\d{9}_(?:\d{4}(?:0[1-9]|1[0-2])(?:0[1-9]|[12][0-9]|3[01]))\.txt$/,
  siret: /^[0-9]{14}$/,
  siren: /^[0-9]{9}$/,
  phone: /^(?:01|02|03|04|05|06|07|09)\d{8}$/,
  email: /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/,
  demoSiren: /^10000000\d[_\d\w-]*$/
};

const REGULAR_EXPRESSIONS_ERROR_TEXTS = {
  mandatoryField: 'Champ obligatoire',
  textWithRangeChars: (minNumbersOfChars, maxNumberOfChars) => `Veuillez entrer un label valide (Entre ${minNumbersOfChars} et ${maxNumberOfChars} caractères)`,
  labelWithMinimumChars: numberOfChars => `Veuillez entrer un label valide (${numberOfChars} caractères minimum, caractères interdits: ', " , ; , / , - )`,
  labelWithRangeChars: (minNumbersOfChars, maxNumberOfChars) =>
    `Veuillez entrer un label valide (Entre ${minNumbersOfChars} et ${maxNumberOfChars} caractères, caractères interdits: ', " , ; , / , - )`,
  label: `Veuillez entrer un label valide (caractères interdits: ', " , ; , / , - )`,
  compteNum: 'Veuillez entrer un numéro de compte valide',
  positiveOrNegativeAmount: 'Veuillez entrer un montant valide',
  url: 'Veuillez entrer une URL valide',
  email: 'Veuillez entrer un email valide'
};

const VAT_RATES = [0, 0.055, 0.1, 0.2];

const PROJECTS_VIEW_TYPES = {
  list: 'list',
  card: 'card'
};

// Disable refresh feature for manual projects
const CONNECTORS_WITH_REFRESH = Object.keys(CONNECTORS).reduce((acc, key) => {
  if (![CONNECTORS.manual].includes(CONNECTORS[key])) {
    return [...acc, CONNECTORS[key]];
  }
  return acc;
}, []);

const LOGO_THEME_VARIANTS = {
  dark: 'dark',
  light: 'light'
};

// TODO : to be defined with adrian ?
const DEFAULT_WORKSPACE = {
  workspace_id: 'DeFi',
  workspace_name: 'DeFi'
};

const EMBED_REPORT_DEFAULT_SETTINGS = {
  visualRenderedEvents: true,
  layoutType: models.LayoutType.Master,
  hideErrors: true,
  panes: {
    filters: {
      expanded: false,
      visible: false
    },
    pageNavigation: {
      visible: false
    }
  }
};

const EMBED_EDITABLE_REPORT_SETTINGS = {
  useCustomSaveAsDialog: true,
  layoutType: models.LayoutType.Master,
  hideErrors: true,
  panes: {
    filters: {
      expanded: false,
      visible: true
    },
    fields: {
      expanded: false
    },
    visualizations: {
      expanded: false
    },
    pageNavigation: {
      visible: true
    }
  }
};

const CHATBOT_SIDEBAR_WIDTH = 550;

const CONVERSATIONS_TIME_PERIODS_TRANSLATIONS = {
  today: "Aujourd'hui",
  yesterday: 'Hier',
  last7Days: 'Les 7 derniers jours',
  last30Days: 'Les 30 derniers jours',
  thisMonth: 'Ce mois-ci',
  thisYear: 'Cette année'
};

// const VISUALS_TYPES_USER_CAN_SHARE_WITH_COPILOT = ['pivotTable', 'clusteredColumnChart', 'clusteredBarChart', 'areaChart', 'pieChart', 'lineChart'];
const VISUALS_TYPES_USER_CAN_SHARE_WITH_COPILOT = ['pivotTable'];

const CHATBOT_USER_MESSAGE_TYPES = {
  text: 'text',
  accountingFile: 'accountingFile'
};

const CHATBOT_MESSAGES_ROLES = {
  assistant: 'assistant',
  user: 'user'
};

const CHATBOT_ASSISTANT_ANSWER_STATE = {
  thinking: 'thinking',
  answering: 'answering'
};

const MAXIMUM_NUMBER_OF_CHABOT_MESSAGES_IN_TRIAL = 10;
const MAXIMUM_NUMBER_OF_CHABOT_THREADS = 2;

const TIIME_AUTHORIZE_URL = 'https://auth0.tiime.fr/authorize';
const TIIME_CLIENT_ID = 'M7VXA43KRbiMxx13gN85W8CcwDfzA5j2';

const appBaseUrl = window.location.origin.toString();
const tiimeOauthCallbackRoute = '/tiime-oauth-callback';
const TIIME_OAUTH_CALLBACK_REDIRECT_URI = `${appBaseUrl}${tiimeOauthCallbackRoute}`;

const SPREADSHEET_TARGETS = {
  BUDGET: 'budget',
  FORECAST: 'forecast'
};

const SPINNER_TYPES = {
  DEFAULT: 'default',
  RSE: 'rse'
};

const EMPLOYEE_TYPES = {
  PARTNER: 'partner'
};

const APP_ROUTES = {
  home: '/',
  login: '/login',
  subscribe: '/subscribe',
  report: reportId => `/reports/${reportId}`,
  projects: '/mes-projets',
  subscription: '/mon-abonnement',
  profile: '/mon-profil',
  bills: '/mes-factures',
  thanksForPurchase: '/merci',
  help: (documentGroup, documentId) => `/${documentGroup}/${documentId}`,
  restrictedAccess: '/restricted-access'
};

const SUMMARY_THEMES = {
  ca: "Chiffre d'affaires HT",
  buyings: 'Achats',
  externalCharges: 'Charges externes',
  peopleCharges: 'Charges de personnel'
};

const USERS_AUTHORIZATION_LEVELS = {
  workspace: 'workspace',
  report: 'report'
};

const REPORT_PAGES_WITHOUT_AUDIO = [POWER_BI_PAGES.reportSynthesis.id]; // TODO Maybe one day this page will have audio and we will have to remove this
const MAXIMUM_NUMBER_OF_WORKSPACES = 2;
const MAXIMUM_NUMBER_OF_CUSTOM_REPORTS = 30;

const TRANSCRIPT_AUDIO_FILE_SIZE_WARNING_MESSAGE = 20;
const MAXIMUM_TRANSCRIPT_AUDIO_FILE_SIZE = 25;

const REPORT_LOADING_STEPS = {
  REPORT_LOADING: 'reportLoading',
  PROJECT_BOOKMARK_APPLYING: 'projectBookmarkApplying'
};

export {
  API_ENDPOINTS,
  CONNECTORS,
  MONTHS,
  POWER_BI_REPORTS,
  POWER_BI_PAGES,
  POWER_BI_REPORT_FILTERS,
  POWER_BI_SLICERS,
  POWER_BI_REPORT_BUTTONS,
  POWER_BI_REPORT_BOOKMARKS,
  POWER_BI_VISUALS,
  DEFAULT_PROJECTS_SCHEMAS,
  STRIPE_PORTAL_URLS,
  BASE_URL,
  REPORT_LABELS,
  REPORT_URL_FORMATTED_IDS,
  SNACKBAR_ACTIONS,
  BREAKPOINTS,
  REGULAR_EXPRESSIONS,
  REGULAR_EXPRESSIONS_ERROR_TEXTS,
  VAT_RATES,
  PROJECTS_VIEW_TYPES,
  CONNECTORS_WITH_REFRESH,
  LOGO_THEME_VARIANTS,
  DEFAULT_WORKSPACE,
  EMBED_REPORT_DEFAULT_SETTINGS,
  EMBED_EDITABLE_REPORT_SETTINGS,
  CHATBOT_SIDEBAR_WIDTH,
  CONVERSATIONS_TIME_PERIODS_TRANSLATIONS,
  VISUALS_TYPES_USER_CAN_SHARE_WITH_COPILOT,
  CHATBOT_USER_MESSAGE_TYPES,
  CHATBOT_MESSAGES_ROLES,
  CHATBOT_ASSISTANT_ANSWER_STATE,
  MAXIMUM_NUMBER_OF_CHABOT_MESSAGES_IN_TRIAL,
  MAXIMUM_NUMBER_OF_CHABOT_THREADS,
  TIIME_AUTHORIZE_URL,
  TIIME_CLIENT_ID,
  TIIME_OAUTH_CALLBACK_REDIRECT_URI,
  SPREADSHEET_TARGETS,
  SPINNER_TYPES,
  EMPLOYEE_TYPES,
  APP_ROUTES,
  SUMMARY_THEMES,
  REPORT_PAGES_WITHOUT_AUDIO,
  MAXIMUM_NUMBER_OF_WORKSPACES,
  MAXIMUM_NUMBER_OF_CUSTOM_REPORTS,
  MAXIMUM_TRANSCRIPT_AUDIO_FILE_SIZE,
  TRANSCRIPT_AUDIO_FILE_SIZE_WARNING_MESSAGE,
  REPORT_LOADING_STEPS,
  USERS_AUTHORIZATION_LEVELS
};
