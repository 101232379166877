import {memo, useEffect, useRef, useState} from 'react';

import {ReactGrid} from '@silevis/reactgrid';
import {useWindowSize} from '@uidotdev/usehooks';
import {arrayOf, func} from 'prop-types';

import '@silevis/reactgrid/styles.scss';
import '../../styles/spreadsheet/index.scss';

import useDimensions from '../../hooks/dom/useDimensions';
import useTranscript from '../../hooks/providers/useTranscript';
import {workingDayTranscriptionDataShape} from '../../proptypes-shapes';
import {
  createDateObjectFromFrenchFormattedDate,
  TRANSCRIPT_WORKING_DAY_COLUMNS as COLUMNS,
  getTranscriptWorkingDaySpreadsheetColumns,
  TRANSCRIPT_WORKING_DAY_SPREADSHEET_HEADER_ROW as HEADER_ROW,
  mappingCellTypeAndPropertyToSet
} from '../../utils';
import DeleteCellTemplate from '../embed/spreadsheet/cell-templates/DeleteCellTemplate';
import InvisibleCell from '../embed/spreadsheet/cell-templates/InvisibleCell';
import TooltipTextCellTemplate from '../embed/spreadsheet/cell-templates/TooltipTextCellTemplate';
import UnfocusableCell from '../embed/spreadsheet/cell-templates/UnfocusableCell';

const TranscriptWorkingDaySpreadsheet = ({data, updateData, deleteRow}) => {
  const [columns, setColumns] = useState([]);
  const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
  const [isMissionDropdownOpen, setIsMissionDropdownOpen] = useState(false);
  const [isTaskDropdownOpen, setIsTaskDropdownOpen] = useState(false);

  const {getReportMissionsForSpreadsheet, getReportTasksCodesForSpreadsheet} = useTranscript();

  const gridRef = useRef(null);
  const [ref, dimensions] = useDimensions();
  const windowSize = useWindowSize();

  useEffect(() => {
    const {width} = dimensions;
    const cols = getTranscriptWorkingDaySpreadsheetColumns(width);
    setColumns(cols);
  }, [ref, dimensions, windowSize]);

  // TODO REFACTOR DRY
  const handleClientDropdownOpenOrClose = change => {
    if (change.type === 'dropdown' && change.columnId === COLUMNS.folder.name) {
      if (change.previousCell.isOpen !== change.newCell.isOpen) {
        setIsClientDropdownOpen(change.newCell.isOpen ? change.rowId : false);
      }
    }
  };

  const handleMissionDropdownOpenOrClose = change => {
    if (change.type === 'dropdown' && change.columnId === COLUMNS.mission.name) {
      if (change.previousCell.isOpen !== change.newCell.isOpen) {
        setIsMissionDropdownOpen(change.newCell.isOpen ? change.rowId : false);
      }
    }
  };

  const handleTaskDropdownOpenOrClose = change => {
    if (change.type === 'dropdown' && change.columnId === COLUMNS.task.name) {
      if (change.previousCell.isOpen !== change.newCell.isOpen) {
        setIsTaskDropdownOpen(change.newCell.isOpen ? change.rowId : false);
      }
    }
  };

  // TODO REFACTOR DRY
  const getClientCell = entry => {
    if (entry.folderOptions) {
      const clientDropdownOptions = entry.folderOptions.map(c => ({
        label: c,
        value: c
      }));
      return {
        type: 'dropdown',
        selectedValue: entry.folder,
        values: clientDropdownOptions,
        isOpen: isClientDropdownOpen === entry.id
      };
    }

    return {
      nonEditable: true,
      type: 'text',
      text: entry.folder
    };
  };

  const getMissionCell = entry => {
    const missionDropdownOptions = getReportMissionsForSpreadsheet()?.map(m => ({
      label: m,
      value: m
    }));

    return {
      type: 'dropdown',
      selectedValue: entry.mission,
      values: missionDropdownOptions,
      isOpen: isMissionDropdownOpen === entry.id
    };
  };

  const getTaskCodeCell = entry => {
    const taskDropdownOptions = getReportTasksCodesForSpreadsheet(entry.mission);

    return {
      type: 'dropdown',
      selectedValue: entry.task,
      values: taskDropdownOptions,
      isOpen: isTaskDropdownOpen === entry.id
    };
  };

  const getTotalHoursSpent = () => {
    const numberHoursSpent = data?.reduce((accumulator, entry) => {
      return accumulator + entry.duration;
    }, 0);

    return numberHoursSpent.toString().replace('.', ',');
  };

  const getTotalHoursSpentRow = () => {
    const totalHoursSpent = getTotalHoursSpent();

    const emptyCell = {
      type: 'invisibleCell'
    };

    return {
      rowId: 'total',
      height: 40,
      cells: [
        emptyCell,
        emptyCell,
        emptyCell,
        emptyCell,
        {
          nonEditable: true,
          type: 'unfocusableCell',
          text: `Total heures:`,
          className: 'total-hours-spent-cell invisible-cell'
        },
        {
          nonEditable: true,
          type: 'unfocusableCell',
          text: `${totalHoursSpent}`,
          className: 'total-hours-spent-cell invisible-cell'
        },
        emptyCell
      ]
    };
  };

  const getRows = transcriptionData => {
    const r = [];

    transcriptionData?.forEach(entry => {
      const clientCell = getClientCell(entry);
      const missionCell = getMissionCell(entry);
      const taskCodeCell = getTaskCodeCell(entry);

      r.push({
        rowId: entry.id,
        height: 40,
        cells: [
          {type: 'date', date: createDateObjectFromFrenchFormattedDate(entry.date)},
          clientCell,
          {type: 'text', text: entry.action},
          missionCell,
          taskCodeCell,
          {type: 'number', value: entry.duration},
          // eslint-disable-next-line no-use-before-define
          {type: 'deleteRow', onDelete: () => handleDeleteRow(entry.id)}
        ]
      });
    });

    const totalHoursRow = getTotalHoursSpentRow();
    r.push(totalHoursRow);
    return [HEADER_ROW, ...r];
  };

  const rows = getRows(data);

  const applyChangesToEntries = (changes, previousData) => {
    const updatedData = [...previousData];

    changes.forEach(change => {
      const {rowId, columnId, type} = change;

      // TODO refactor : DRY
      handleClientDropdownOpenOrClose(change);
      handleMissionDropdownOpenOrClose(change);
      handleTaskDropdownOpenOrClose(change);

      const cellPropertyToSet = mappingCellTypeAndPropertyToSet[type];
      const oldValue = change.previousCell[cellPropertyToSet];
      const newValue = change.newCell[cellPropertyToSet];

      if (newValue !== oldValue) {
        const itemToUpdateIndex = updatedData.findIndex(item => item.id === rowId);
        const updatedItem = updatedData[itemToUpdateIndex];
        updatedItem[columnId] = newValue;

        // When we select a new mission, we have to reset previously selected task
        if (change.columnId === COLUMNS.mission.name) {
          const tasks = getReportTasksCodesForSpreadsheet(newValue);

          // eslint-disable-next-line prefer-destructuring
          updatedItem.task = tasks[0].value;
        }

        updatedData[itemToUpdateIndex] = updatedItem;
      }
    });

    return [...updatedData];
  };

  const handleChanges = changes => {
    updateData(previousData => applyChangesToEntries(changes, previousData));
  };

  const handleDeleteRow = id => {
    deleteRow(id);
    gridRef.current.updateState(state => {
      if (state.focusedLocation) {
        return {
          ...state,
          focusedLocation: {
            row: rows[1],
            column: state.focusedLocation?.column
          },
          selectedRanges: []
        };
      }
      return state;
    });
  };

  return (
    <div style={{zIndex: 2, position: 'relative'}} ref={ref} id={`reactgrid-${process.env.REACT_APP_COMPANY_NAME}`}>
      {columns.length > 0 && data && data.length > 0 && (
        <ReactGrid
          enableRowSelection
          ref={gridRef}
          initialFocusLocation={{columnId: 'col-3', rowId: 'row-3'}}
          onCellsChanged={handleChanges}
          rows={rows}
          columns={columns}
          customCellTemplates={{
            unfocusableCell: new UnfocusableCell(),
            invisibleCell: new InvisibleCell(),
            deleteRow: new DeleteCellTemplate(),
            tooltipTextCell: new TooltipTextCellTemplate()
          }}
        />
      )}
    </div>
  );
};

TranscriptWorkingDaySpreadsheet.propTypes = {
  data: arrayOf(workingDayTranscriptionDataShape).isRequired,
  deleteRow: func.isRequired,
  updateData: func.isRequired
};

export default memo(TranscriptWorkingDaySpreadsheet);
