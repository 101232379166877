/* eslint-disable react/boolean-prop-naming */
import {useState} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {createTheme, Divider, useTheme, ThemeProvider, IconButton, Grid} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {bool, func, string, node, oneOfType, arrayOf, shape} from 'prop-types';

const createDialogTheme = parentTheme => {
  return createTheme({
    ...parentTheme, // spreading parent theme allows to keep all base stuff configured in it (eg: color palette)
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            padding: 1
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            paddingTop: 8,
            paddingBottom: 0
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            width: '50%',
            [parentTheme.breakpoints.down('md')]: {
              width: '80%'
            },
            margin: '0 auto'
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontWeight: 800,
            textAlign: 'center',
            fontSize: 18,
            padding: parentTheme.spacing(1.5),
            fontFamily: 'SoehneBreitKraftig',
            [parentTheme.breakpoints.down('md')]: {
              padding: parentTheme.spacing(1),
              fontSize: 17
            }
          }
        }
      }
    }
  });
};

const BaseModal = props => {
  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(false);

  const {hasFullScreenButton, withoutTitle, onClose, title, open, children, sx, hasCloseIcon, canCloseOnBackdropClick, overridesPaperProps, maxWidth = 'sm'} = props;

  const parentTheme = useTheme();
  const dialogTheme = createDialogTheme(parentTheme);

  const paperProps = {
    sx: {
      maxHeight: 'calc(100% - 8px)',
      margin: {
        xs: 1,
        sm: 2,
        md: 3
      },
      width: {
        xs: '100%'
      },
      ...overridesPaperProps
    }
  };

  const renderTitle = () => {
    if (withoutTitle) return null;

    if (hasCloseIcon || hasFullScreenButton) {
      return (
        <Grid container alignItems="center">
          <DialogTitle sx={{flex: 1}} ml={6}>
            {title}
          </DialogTitle>

          {hasFullScreenButton ? (
            <Grid item mr={1} sx={{cursor: 'pointer'}} onClick={() => setIsFullScreenEnabled(!isFullScreenEnabled)}>
              {isFullScreenEnabled ? <FullscreenExitIcon sx={{fontSize: 30}} /> : <FullscreenIcon sx={{fontSize: 30}} />}
            </Grid>
          ) : null}

          {hasCloseIcon ? (
            <Grid item mr={1}>
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  color: theme => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          ) : null}

          <Divider sx={{my: 0.5, width: '100%'}} />
        </Grid>
      );
    }

    return (
      <>
        <DialogTitle>{title}</DialogTitle>
        <Divider sx={{my: 0.5}} />
      </>
    );
  };

  const handleClose = (event, reason) => {
    if (!canCloseOnBackdropClick && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <ThemeProvider theme={dialogTheme}>
      <Dialog fullScreen={isFullScreenEnabled} sx={sx} maxWidth={maxWidth} onClose={(e, r) => handleClose(e, r)} open={open} fullWidth PaperProps={paperProps}>
        {renderTitle()}
        {children}
      </Dialog>
    </ThemeProvider>
  );
};

BaseModal.defaultProps = {
  sx: {},
  maxWidth: 'sm',
  title: '',
  withoutTitle: false,
  hasCloseIcon: false,
  canCloseOnBackdropClick: true,
  overridesPaperProps: null,
  hasFullScreenButton: false
};

BaseModal.propTypes = {
  onClose: func.isRequired,
  open: bool.isRequired,
  title: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
  sx: shape({}),
  maxWidth: string,
  withoutTitle: bool,
  hasCloseIcon: bool,
  canCloseOnBackdropClick: bool,
  overridesPaperProps: shape({}),
  hasFullScreenButton: bool
};

export default BaseModal;
